import * as React from 'react'
import styled from 'styled-components'
import Loading from '../components/shared/loading'
import queryString from "querystring";
import axios from "axios";
import {config} from "../config";

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;`

const LoginPage = () => {


    React.useEffect(() => {
        const loginAndRedirect = async () => {
            const { next, token } = queryString.parse(window.location.search.substr(1))
            if (token && next) {
                let user = (
                    await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)
                ).data
                if (token && next) {
                    window.localStorage.setItem('user', JSON.stringify(user))
                    window.localStorage.setItem('token', token)
                    window.localStorage.setItem('registered', '1')
                    window.location.href = next
                }
            }
        }
        loginAndRedirect().then().catch(console.log)
    }, [])

    return (
        <div>
            <CenteredDiv>
                <Loading />
            </CenteredDiv>
        </div>
    )
}

export default LoginPage
